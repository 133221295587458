.label {
  position: relative;
  border-top: 1px solid;
  border-color: var(--quickSeatRowSeparator);
  color: var(--quickSeatRowText);
  border-top-color: var(--quickSeatRowSeparator);
  padding: 10px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  height: 37px;
  box-sizing: border-box;
}

.button {
  background: linear-gradient(to bottom, #f6f6f6, #ffffff);
  color: var(--quickSeatRowText);
  min-width: 306px;
  overflow: hidden;
}

.button:not(.selected):hover {
  background: var(--quickSeatRowHoverBackground);
}

.button.selected {
  background: var(--quickSeatRowHoverBackground);
}

.buttonLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 65%;
}

.icon {
  width: 20px;
  display: block;
  float: right;
  text-align: center;
  background: var(--tableFeltColor);
  color: #fff;
  font-style: normal;
  border-radius: 15px;
  box-shadow: 1px 1px 1px #888;
  padding: 5px;
  opacity: 0.2;
  position: relative;
  bottom: 5px;
  transition: 0.2s ease;
  margin-right: 0;
}

.icon:not(.selected):hover {
  opacity: 1;
}

.icon.stake {
  background: #1c752c;
}

.icon.selected {
  opacity: 1;
}

.header {
  background: var(--quickSeatHeadingBackground);
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.selection {
  margin: 0;
  overflow: auto;
  display: flex;
  padding: .5em;
  box-sizing: border-box;
  min-height: 0;
}

.mobileTabBar {
  background: black;
  z-index: 10;
  flex-shrink: 0;
  display: flex;
}

.mobileTab {
  flex: 1;
  background: linear-gradient(180deg, rgba(60, 60, 60, 1) 0%, rgba(35, 35, 35, 1) 100%);
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #AAA;
  font-size: 90%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  min-width: 100px;
}

.mobileTab.active {
  cursor: default;
  background: linear-gradient(180deg, #333333 0%, #191919 100%);
  border-bottom-color: var(--quickSeatTabSelectedTrim);
  color: white;
}

.mobileTab :first-child {
  font-size: 70%;
  color: #999;
}

.buttonGroup {
  margin: .5em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fdfdfd;
  font-size: .9em;
  white-space: nowrap;
  flex: 1;
  max-width: 50%;
  height: max-content;
}

.tableCount {
  float: right;
  margin-right: 10px;
  max-width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 1200px) and (min-width:  1000px) {
  .buttonLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    max-width: 50%;
  }

  .button {
    min-width: 260px;
  }
}

@media all and (max-width: 1000px) {
  .icon.selected {
    transform: rotate(90deg);
  }

  .selection {
    flex-direction: column;
  }

  .buttonGroup {
    max-width: initial;
  }
}
