.gameDetails {
  position: absolute;
  font-size: 1.2em;
  width: 15%;
  top: 2%;
  left: 1%;
  z-index: 1;
}

.row {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.spacer {
  margin-top: 3%;
}

.badbeatBalance {
  color: #d7b23a;
}
