.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 75%;
  height: 4.95em;
  max-height: 75px;
  z-index: 1000;
  transition: all 0.5s ease;
}

.playHand, .breakTime {
  height: 100%;
}

.action {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(54, 54, 54, 0.25) 6%, rgba(63, 63, 63, 0.63) 7%, rgba(238, 238, 238, 1) 8%, rgba(190, 190, 190, 1) 100%);
  border: none;
  border-radius: 0.1em;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
  color: #2c2c2c;
  text-align: center;
  background-color: #999;
  min-width: 100px;
  width: 24.5%;
  height: 100%;
  margin: 0.25%;
  font-family: "Helvetica", sans-serif;
  font-size: 1.5em;
  font-weight: 300;
}

.action:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(54, 54, 54, 0.25) 6%, rgba(63, 63, 63, 0.63) 7%, rgba(220, 220, 220, 1) 8%, rgba(170, 170, 170, 1) 100%);
  background-color: #ccc;
  color: black;
}

.action:active {
  background-color: #eee;
  top: 1px;
  position: relative;
}

.action.allIn {
  background-color: #f00;
}

.action.bet {
  background-color: #f60;
}

.action.call {
  background-color: #cc9900;
}

.action.check {
  background-color: #87ad00;
}

.action.raise {
  background-color: #f60;
}

.action.fold {
  background-color: #008282;
}

.preactions .action.allIn,
.preactions .action.bet,
.preactions .action.call,
.preactions .action.check,
.preactions .action.raise,
.preactions .action.fold {
  background: none;
}

.preactions {
  background-color: rgba(0, 0, 0, 0.3);
}

.preactions button:hover {
  background: none;
  color: white;
}

.preactions button:active {
  background: none;
}

.preactions button {
  background: none;
  border: none;
  box-shadow: none;
  color: #eee;
  min-width: 100px;
  width: 24.5%;
  margin: 0.25%;
  font-size: 1.5em;
}

.preactions button div {
  background: black;
  border-radius: 50%;
  border: 0.1em solid white;
  display: inline-block;
  margin-right: 1em;
  padding-top: 10%;
  vertical-align: middle;
  width: 11%;
}

.preactions button div.intendedAction {
  background: white;
  box-shadow: inset 0 0 0 2px black
}

.betRaiseAmount {
  position: absolute;
  bottom: 8em;
  right: 15%;
  z-index: 1000;
  padding: 0.5em;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
  border: 0.3em solid #bebebe;
  border-radius: 0.2em;
  box-shadow: 0px 5px 20px 3px rgb(0 0 0 / 50%);
}

.betRaiseAmount .presets button {
  margin: 0.5em 0.2em;
  font-size: 1.5em;
}

button.closeButton {
  background: #940000;
}
