.gameTypeDropdownItem {
  background: #222;
  width: 140px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.gameTypeDropdownItem:hover {
  background: #333;
}
