.group {
  margin: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative; /* stops settings view collapsing when checking checkboxes */
}

.deviceFormat {
  padding: 20px;
  margin: 1em;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
}

.heading {
  display: flex;
  height: 1.5em;
  padding: 10px;
  align-items: center;
  background: linear-gradient(180deg, #3c3c3c 0%, #232323 100%);
  color: #fff;
}

.row {
  display: flex;
  height: 1.5em;
  padding: 10px;
  align-items: center;
  background: linear-gradient(to bottom, #f6f6f6, #fff);
  border-top: 1px solid #e8e8e8;
  color: #2f3e46;
}

.row:hover {
  background: linear-gradient(to bottom, #ededed, #dadada);
}

.subRow {
  padding-left: 30px;
}

.subRowInput {
  padding-left: 50px;
  padding-right: 50px;
}

.label {
  flex: 1;
  order: 1;
}

.input {
  order: 2;
  display: flex;
  align-items: center;
}

.input > * {
  box-sizing: border-box; /* stop checkbox component focus animation from resizing the bounds of the input area */
}

.numberInput {
  width: 60px;
  text-align: center;
  border: 1px solid #ccc;
  margin-right: 6px;
}

.help {
  order: 1;
  border: 0;
  border-radius: 15px;
  margin-right: 10px;
  padding: 8px;
  width: 30px;
  color: #fff;
  background-color: var(--tableFeltColor);
  box-shadow: 1px 1px 1px #888;
  opacity: 0.2;
  transition: 0.2s ease;
}

.help:hover {
  opacity: 1;
}
