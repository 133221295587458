.checkbox {
  align-self: center;
  height: 1.5em;
  border-radius: 1.9em;
}

/* the visually styled svg checkbox */
.svgCheckbox {
  height: 100%;
}

.svgCheckbox :global .svgSquare {
  fill: none;
  width: 19px;
  height: 19px;
  stroke: #888;
}

.svgCheckbox :global .svgCheckmark {
  display: none;
  fill: none;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke: rgb(8, 227, 81);
}

.svgCheckbox.checked :global .svgCheckmark {
  display: initial;
}

.checkbox.focused {
  animation: checkboxFocusAnimaton .5s;
}

.checkbox.disabled .svgSquare {
  fill: #969696;
}

.checkbox.disabled .svgCheckmark {
  stroke: rgb(193 193 193);
}

/* the native checkbox that is visually hidden */
.nativeCheckbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@keyframes checkboxFocusAnimaton {
  0% {
    background-color: transparent;
  }
  50%  {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.1em;
    margin: -1.1em;
    height: 3.7em;
  }
  100% {
    background-color: transparent;
    padding: 1.1em;
    margin: -1.1em;
    height: 3.7em;
  }
}
