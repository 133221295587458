.slider {
  margin: 0.75em 0;
}

.slider > input[type=range] {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
}

.slider > input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.slider > input[type=range]:focus {
  outline: none;
}

.slider > input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(190, 190, 190, 1) 100%);
  border-radius: 0.2em;
  border: 0;
  cursor: pointer;
  height: 2em;
  width: 2em;
  margin-top: -0.5em;
}

.slider > input[type=range]::-moz-range-thumb {
  background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(190, 190, 190, 1) 100%);
  border-radius: 0.2em;
  border: 0;
  cursor: pointer;
  height: 2em;
  width: 2em;
}

.slider > input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.2em;
  border: 0;
  cursor: pointer;
  height: 1em;
}

.slider > input[type=range]:focus::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.4);
}

.slider > input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 0.2em;
  border: 0;
  cursor: pointer;
  height: 1em;
  width: 100%;
}
