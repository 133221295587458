.wrapper {
  margin: 6em auto auto;
  grid-area: stats/stats/chat/actions;
  z-index: 2;
}

@media (max-height: 320px) {
  .wrapper {
    margin: 6em 5em auto auto;
  }
}

.container {
  width: 65em;
  height: 34em;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.background {
  border-radius: 16.9em;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  position: relative;
  margin: auto;
  background-color: var(--tableFeltColor);
  box-shadow:
    .5em 1em 2em rgba(0,0,0,0.45), /* outer shadow */
    inset .1em .2em .2em var(--tableRailColorHighlight), /* top highlight accent */
    inset -.1em -.2em .4em rgba(0,0,0,.8), /* bottom shadow accent */
    inset 0 0 .1em 2em var(--tableRailColor), /* outer rail */
    inset 0 0 0 2.3em var(--tableRailInnerColor), /* inner rail */
    inset -.1em .4em 1.1em 2.2em rgba(0,0,0,.4), /* larger inner shadow */
    inset .1em .1em .1em 2em var(--tableRailColorShadow), /* top shadow accent */
    inset -.1em -.1em .1em 2em var(--tableRailColorHighlight) /* bottom highlight accent */
  ;
}

.background::before, .background::after {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
}

.background::before {
  background: no-repeat center;
  background-image: var(--tableLogo);
  background-size: var(--tableLogoSize);
  opacity: 0.7;
}

.background::after {
  position: absolute;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    ellipse at 40% -10%,
    rgba(255, 255, 255, .1),
    transparent 50%,
    rgba(0, 0, 0, .2) 80%
  );
}
