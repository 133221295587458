.radio {
  align-self: center;
  height: 1.5em;
  border-radius: 1.9em;
}

/* the visually styled svg radio */
.svgRadio {
  height: 100%;
}

.svgRadio :global .svgCircle {
  fill: none;
  stroke: #888;
  stroke-width: 0.5px;
}

.svgRadio :global .svgCheckmark {
  display: none;
  fill: rgb(8, 227, 81);
}

.svgRadio.checked :global .svgCheckmark {
  display: initial;
}

.radio.focused {
  animation: radioFocusAnimaton .5s;
}

.radio.disabled .svgCircle {
  fill: #969696;
}

.radio.disabled .svgCheckmark {
  fill: rgb(193 193 193);
}

/* the native radio that is visually hidden */
.nativeRadio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@keyframes radioFocusAnimaton {
  0% {
    background-color: transparent;
  }
  50%  {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.1em;
    margin: -1.1em;
    height: 3.7em;
  }
  100% {
    background-color: transparent;
    padding: 1.1em;
    margin: -1.1em;
    height: 3.7em;
  }
}
