.skeletonize,
.skeletonize * {
  color: transparent !important;
  user-select: none !important;
  box-shadow: none !important;
}
.skeletonize .mid,
.skeletonize .light,
.skeletonize .dark {
  animation-name: loading !important;
  animation-duration: 3s !important;
  animation-iteration-count: infinite !important;
}
.skeletonize .mid {
  background-color: hsl(0, 0%, 85%) !important;
}
.skeletonize li.li-mid::marker {
  color: hsl(0, 0%, 85%) !important;
}
.skeletonize .light {
  background-color: hsl(0, 0%, 90%) !important;
}
.skeletonize li.li-light::marker {
  color: hsl(0, 0%, 90%) !important;
}
.skeletonize .dark {
  background-color: hsl(0, 0%, 75%) !important;
}
.skeletonize li.li-dark::marker {
  color: hsl(0, 0%, 75%) !important;
}

.skeletonize .hide {
  display: none;
}

@keyframes loading {
  0%   { opacity: 0.75; }
  25%  { opacity: 1; }
  50%  { opacity: 0.5; }
  75%  { opacity: 1; }
  100% { opacity: 0.75; }
}
