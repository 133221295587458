.active {
  background: var(--headerTabHoverBackground);
  opacity: 1;
}

.hasDropdown {
  position: relative;
}

.hasDropdown::before {
  display: block;
  content: '';
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-color: transparent transparent #aaa transparent;
}

.tabButton {
  width: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabButton * {
  box-sizing: border-box;
}

.tabButton {
  width: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabButton :global .icon {
  transform: scale(1.3);
}

.tabButton :global .label {
  font-size: .6em;
  margin-top: .4em;
}

.tabButton :global .fa {
  font-size: 2.1em;
}

.tabButton :global .icon,
.tabButton :global .label,
.tabButton :global .fa {
  opacity: 0.7;
}
