.lobbyHeader {
  grid-area: header;
  background: var(--lobbyHeaderBackground);
  display: flex;
}

.logo {
  flex: 1;
  background: var(--headerHomeLogo);
  background-size: var(--headerHomeLogoSize);
}

.iconMenuUpdate {
  position: relative;
  opacity: 1;
}

.iconMenuUpdate::after {
  content: '';
  display: block;
  background-color: limegreen;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 2.5px;
  position: absolute;
  top: 2.5px;
}
