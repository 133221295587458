.container {
  grid-area: stats;
  margin: 0 0 8px 8px;
  z-index: 999;
  align-self: flex-end;
  max-width: max-content;
  font-size: 15px;
}

.heading {
  text-transform: capitalize;
  letter-spacing: .08em;
  margin-bottom: .3em;
  font-size: .8em;
}

.auto {
  margin-bottom: .3em;
}

.sitOutNextHand {
  margin-bottom: .3em;
}

@media screen and (max-width: 1023px), (max-height: 767px) {
  .container > .auto {
    display: none;
  }
}
