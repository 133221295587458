.latency {
  margin-left: 3em;
  float: left;
}

.row {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.strength {
  font-size: 100%;
  position: absolute;
  left: 0;
  width: 2.4em !important;
  height: 1.1em;
  overflow: hidden;
  z-index: 1;
}

.strength::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 0.35em;
  height: 1.1em;
  background: #969494;
}

.bars-0:after {
  background: transparent;
}

.bars-1:after {
  box-shadow: #969494 -0.55em 0.2em 0, #969494 -1em 0.4em 0, #969494 -1.5em 0.6em 0, #fff -2em 0.8em 0;
}

.bars-2:after {
  box-shadow: #969494 -0.55em 0.2em 0, #969494 -1em 0.4em 0, #fff -1.5em 0.6em 0, #fff -2em 0.8em 0;
}

.bars-3:after {
  box-shadow: #969494 -0.55em 0.2em 0, #fff -1em 0.4em 0, #fff -1.5em 0.6em 0, #fff -2em 0.8em 0;
}

.bars-4:after {
  box-shadow: #fff -0.55em 0.2em 0, #fff -1em 0.4em 0, #fff -1.5em 0.6em 0, #fff -2em 0.8em 0;
}

.bars-5:after {
  box-shadow: #fff -0.55em 0.2em 0, #fff -1em 0.4em 0, #fff -1.5em 0.6em 0, #fff -2em 0.8em 0;
  background: #fff;
}
