.toolbar {
  position: relative;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #333;
  height: 42px;
  flex-shrink: 0;
}

.tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 6px 0 0;
  border-radius: 5px;
  font-size: 70%;
  background: var(--lobbyToolbarTabBackground);
  color: #eee;
  margin: 4px;
}

.tab .icon {
  transform: scale(6 / 10);
}

.tab:first-child {
  padding-left: 6px;
}
.tab:hover {
  background: #222;
  color: #fff;
}
.tab.active {
  background: var(--lobbyToolbarTabSelectedBackground);
}
