.stepper {
  width: max-content;
  background: whitesmoke;
  color: black;
  font-size: 240%;
  text-align: center;
  margin: 0.1em auto;
  display: block;
  border-radius: 0.2em;
}

.less, .more {
  font-size: 80% !important;
  width: 1.2em;
  padding: 0;
  border: 0;
  color: black;
  background: transparent;
  cursor: pointer;
}

.value {
  background: black;
  color: whitesmoke;
  display: inline-block;
  width: 6em;
  padding: 3px;
  font-size: 100%;
  text-align: center;
  vertical-align: middle;
  border: 2px solid whitesmoke;
  border-radius: 0.2em;
}

/* hide the standard browser number input up/down buttons */
.value {
  -moz-appearance: textfield;
}
.value::-webkit-inner-spin-button,
.value::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hiddenSlider {
  display: none;
}
