.form label {
  display: block;
  margin-top: 1em;
}

.form select,
.form button {
  display: inline-block;
  margin-top: 1em;
}

.form button {
  margin-left: 1em;
}
