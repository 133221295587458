.selection {
  display: flex;
  height: 200px;
  background: linear-gradient(180deg, #333 0%, #222 100%);
}

.selection ul {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 25%;
}

.selection button {
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin: 0;
  padding: 10px;
  padding-bottom: 9px;

  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: left;

  background-color: var(--gameListSelectionButtonColor);
  color: white;
}

.selection button:hover {
  background-color: var(--gameListSelectionButtonHoverColor);
}

.selection button.selected {
  background-color: var(--gameListSelectionButtonSelectedColor);
}

.lobbyTabBar {
  background: #000;
  flex-shrink: 0;
  z-index: 10;
  min-height: 0;
  overflow: auto;
}

.mobileSelection {
  display: flex;
}

.lobbyTab {
  flex: 1;
  background: linear-gradient(180deg, #3c3c3c 0%, #232323 100%);
  cursor: pointer;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #aaa;
  font-size: 90%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  min-width: 100px;
}

.lobbyTabBar.ringGames .lobbyTab {
  min-width: 120px;
}

.lobbyTab.active {
  background: linear-gradient(180deg, #333, #191919);
  border-bottom-color: #888;
  color: #fff;
  cursor: default;
}

.lobbyTab :first-child {
  color: #999;
  font-size: 70%;
}
