.labeledRadio {
  background-color: #1f2325;
  display: flex;
  border-radius: .5em;
  padding: .8em;
  font-size: 1em;
  overflow: hidden; /* stop radio focus animation from apearing outside the bounds of the label */
}

.text {
  padding-left: .65em;
  align-self: center;
}

.disabled {
  color: #545454;
}

.small.labeledRadio {
  font-size: .75em;
}

.medium.labeledRadio {
  font-size: 1em;
}

.large.labeledRadio {
  font-size: 1.25em;
}
