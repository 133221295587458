.tableView {
  height: 100%;
  display: flex;
}

.tableView * {
  box-sizing: border-box;
}

.tableViewGrid {
  width: 100%;
  display: grid;
  grid-template-areas:
      "stats . table-controls"
      "chat-log . timebank"
      "chat . actions";
  grid-template-rows: auto .2fr 2.6rem;
  grid-template-columns: 1fr 2.7em 75%;
}

:global .format-mobile :local .tableViewGrid,
:global .format-tablet :local .tableViewGrid {
  grid-template-areas:
      "stats . table-controls"
      "chat . actions";
  grid-template-rows: 1fr 2.6rem;
}

@media screen and (max-height: 70px) {
  .tableViewGrid {
    grid-template-rows: 0 1fr; /* make chat grid take up full view */
  }
}
