.presetButtonsPanel {
  padding: 2% 0;
}

.presetButton {
  text-overflow: clip;
}

.etiquetteNotice {
  color: #bbb;
  font-size: 85%;
  padding: 2% 4% 1%;
}

.detailsPanel {
  display: grid;
  grid-auto-flow: column;
  padding: 1% 4%;
}

.detailsColumn {
  color: #bbb;
  font-size: 85%;
  padding: 2% 0 3%;
}

.detailsCell {
  padding: 5px 0;
}

.detailsValue {
  color: white;
  padding-left: 5px;
}

.cashierLink {
  margin-left: 10px;
  font-size: 90%;
  opacity: 0.8;
}
