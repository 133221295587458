.gameOptions {
  display: grid;
  grid-template-areas:
    "left right"
    "bottom bottom";
}

.gameOptions * {
  box-sizing: border-box;
}

.leftSide {
  grid-area: left;
}

.leftSide > label {
  margin-bottom: .3em;
}

.leftSide > div {
  margin-bottom: 1em;
}

.rightSide {
  grid-area: right;
  text-align: right;
}

.rightSide button {
  margin-left: 0.4em;
}

.rightSide a {
  margin-left: 1em;
}

.rightSide > div {
  margin-bottom: 1em;
}

.autoRebuy {
  grid-area: bottom;
}

.autoRebuyPoint {
  width: 100%;
  margin-bottom: 0.4em;
  display: flex;
  justify-content: center;
}

.autoRebuyPointRadio {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.autoRebuyPresets {
  width: 100%;
  margin-top: 0.4em;
  display: flex;
  justify-content: center;
}

.autoRebuyPresets button {
  width: 22%;
  margin-left: 0.2em;
  margin-right: 0.2em;
}
