.table {
  display: table;
  border-collapse: collapse;

  width: 100%;
  font-size: 90%;

  white-space: nowrap;
}

.table, .table a {
  color: hsl(0, 0%, 25%);
}

.caption {
  display: table-caption;
  caption-side: bottom;

  padding: 80px 0;
  font-size: 1.5em;
  font-weight: bold;

  text-align: center;

  border-top: 1px solid #e8e8e8;
  background-image: linear-gradient(to bottom, #f6f6f6, #fff);
}

.cell {
  display: table-cell;

  overflow: hidden;
  text-overflow: ellipsis;

  vertical-align: middle;
  padding: 10px;
}
.cell.alignRight {
  text-align: right;
}
.cell.noPadding {
  padding: 0;
}
.cell.seatingPreview {
  padding: 6px 4px;
  line-height: 0;
}

.header {
  display: table-cell;

  overflow: hidden;
  text-overflow: ellipsis;

  vertical-align: middle;
  padding: 6px 10px;

  font-weight: bold;
}
.header.sortable:hover {
  background-image: linear-gradient(to bottom, #c8c8c8, #fff);
}
.header.sortable.ascending {
  background: center top no-repeat url(SortAscending.svg), linear-gradient(to bottom, #c8c8c8, #fff);
}
.header.sortable.descending {
  background: center top no-repeat url(SortDescending.svg), linear-gradient(to bottom, #c8c8c8, #fff);
}

.headerGroup {
  display: table-header-group;
}

.row {
  display: table-row;

  border-top: 1px solid #e8e8e8;
  background-image: linear-gradient(to bottom, #f6f6f6, #fff);
}
.table a.row:hover {
  background-image: linear-gradient(to bottom, #e6e6e6, #fff);
}
.row.blue {
  border-top: 1px solid hsl(225, 100%, 92%);
  background-image: linear-gradient(to bottom, hsl(225, 100%, 94%), hsl(225, 100%, 98%));
}
.table a.row.blue:hover {
  background-image: linear-gradient(to bottom, hsl(225, 100%, 90%), hsl(225, 100%, 98%));
}
.row.green {
  border-top: 1px solid hsl(150, 53%, 85%);
  background-image: linear-gradient(to bottom, hsl(150, 53%, 93%), hsl(150, 53%, 97%));
}
.table a.row.green:hover{
  background-image: linear-gradient(to bottom, hsl(150, 53%, 87%), hsl(150, 53%, 97%));
}
.row.yellow {
  border-top: 1px solid hsl(60, 100%, 50%);
  background-image: linear-gradient(to bottom, hsl(60, 100%, 85%), hsl(60, 100%, 98%));
}
.table a.row.yellow:hover {
  background-image: linear-gradient(to bottom, hsl(60, 100%, 75%), hsl(60, 100%, 98%));
}

.rowGroup {
  display: table-row-group;
}

a.row {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .cell.expendable,
  .header.expendable {
    display: none;
  }
}

@media (max-width: 1280px) {
  .cell,
  .header {
    max-width: 7.5vw;
  }

  .cell.primary,
  .header.primary {
    max-width: 15vw;
  }
}

@media (min-width: 1281px) {
  .cell,
  .header {
    max-width: unset;
  }

  .cell.primary,
  .header.primary {
    max-width: 400px;
  }
}
