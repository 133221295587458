/* Chips.png is non JC standard
 WC version has 1px spacing on the bottom and sides, for the drop shadow and antialiasing bleed
 JC needs to update their sprite as the chip shadow is cropped off.

 Animation from chip to pot and pot to winner
 a transition occurs when the element is given X Y co-ordinates, the co-ords are
 inserted via inline style by JS */

/* The chips.png is divided horizontally and vertically to show the correct chip icon
/* The x value is 12.5% because 100 / 8 %;
/* The y value 100% because 100 / 1 %;

This moves the entire chips image arround, showing only the chip in the position that matches the
background positions.
/* ------------------------- */

.wrapper {
  display: flex;
}

.icon {
  background-image: url('../../styling/common/images/chips.png');
  background-size: 900%;
  z-index: 100;
  width: 1.4em;
  height: 1.4em;
}

.amount {
  display: flex;
  align-items: center;
  padding: 0 1em 0 1.4em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1em;
  margin-left: -1em;
  min-width: 2.25em;
}

.chipValueC1 {
  background-position: 0 0;
}

.chipValueC5 {
  background-position: 12.5% 0;
}

.chipValueC25 {
  background-position: 25% 0;
}

.chipValueD1 {
  background-position: 37.5% 0;
}

.chipValueD5 {
  background-position: 50% 0;
}

.chipValueD25 {
  background-position: 62.5% 0;
}

.chipValueD100 {
  background-position: 75% 0;
}

.chipValueD500 {
  background-position: 87.5% 0;
}

.chipValueK1 {
  background-position: 100% 0;
}

.chipValueK5 {
  background-position: 0 100%;
}

.chipValueK25 {
  background-position: 12.5% 100%;
}

.chipValueK100 {
  background-position: 25% 100%;
}

.chipValueK500 {
  background-position: 37.5% 100%;
}

.chipValueM1 {
  background-position: 50% 100%;
}

.chipValueM5 {
  background-position: 62.5% 100%;
}

.chipValueM25 {
  background-position: 75% 100%;
}

.chipValueM100 {
  background-position: 87.5% 100%;
}

.chipValueM500 {
  background-position: 100% 100%;
}

:global .chip-container.hide-amount :local .amount {
  display: none;
}
