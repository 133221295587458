.adbarIframe{
  width: 100%;
  height: 100%;
  border: none;
}

.adbarIframe.loadStart {
  height: 0;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.adbarIframe.loaded {
  height: 100%;
  opacity: 1;
}

.sideBar {
  background: var(--defaultAdBarBackground);
  overflow: hidden;
  grid-area: sidebar;
  min-height: 0;
}

@media (max-width: 800px) {
  .sideBar { display: none; }
}
