.loading {
  text-align: center;
}

.loading > div {
  background-color: currentColor;
  height: 90px;
  width: 15px;
  display: inline-block;
  margin-left: 1px;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.loading > div:nth-child(2) {
  animation-delay: -1.1s;
}

.loading > div:nth-child(3) {
  animation-delay: -1s;
}

.loading > div:nth-child(4) {
  animation-delay: -0.9s;
}

.loading > div:nth-child(5) {
  animation-delay: -0.8s;
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
