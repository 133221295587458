.clock {
  right: 0;
  top: 0;
  position: absolute;
  margin: 13px 17px 0 0;
}

.clock.tooltip:before {
  display: none;
  position: absolute;
  right: 0;
  width: 100px;
  content: attr(title);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  font-family: arial;
  font-size: 13px;
  border-radius: 2px;
  color: #eee;
  z-index: 200;
  top: 100%;
  opacity: 0.8;
}

.clock.tooltip:hover:before, .tooltip.showTooltip:before {
  display: block;
}

.clock .icon {
  vertical-align: bottom;
  font-size: 14pt;
}
