.preview {
  --seat1: 0;
  --seat2: 0;
  --seat3: 0;
  --seat4: 0;
  --seat5: 0;
  --seat6: 0;
  --seat7: 0;
  --seat8: 0;
  --seat9: 0;
  --seat10: 0;
}

:global(#seatingPreviewTable) {
  fill: hsl(0, 0%, 25%);
}

:global(.seatingPreviewSeat) {
  stroke: hsl(0, 0%, 25%);
  stroke-width: 1;
}

:global(.seatingPreviewTemplate .seat1) { fill: hsla(0, 0%, 25%, var(--seat1)); }
:global(.seatingPreviewTemplate .seat2) { fill: hsla(0, 0%, 25%, var(--seat2)); }
:global(.seatingPreviewTemplate .seat3) { fill: hsla(0, 0%, 25%, var(--seat3)); }
:global(.seatingPreviewTemplate .seat4) { fill: hsla(0, 0%, 25%, var(--seat4)); }
:global(.seatingPreviewTemplate .seat5) { fill: hsla(0, 0%, 25%, var(--seat5)); }
:global(.seatingPreviewTemplate .seat6) { fill: hsla(0, 0%, 25%, var(--seat6)); }
:global(.seatingPreviewTemplate .seat7) { fill: hsla(0, 0%, 25%, var(--seat7)); }
:global(.seatingPreviewTemplate .seat8) { fill: hsla(0, 0%, 25%, var(--seat8)); }
:global(.seatingPreviewTemplate .seat9) { fill: hsla(0, 0%, 25%, var(--seat9)); }
:global(.seatingPreviewTemplate .seat10) { fill: hsla(0, 0%, 25%, var(--seat10)); }
