.list {
  background: #fff;
  overflow: auto;
}

.list, .list a {
  color: hsl(0, 0%, 25%);
}

.caption {
  caption-side: bottom;
  padding: 80px 0;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  background-image: linear-gradient(to bottom, #f6f6f6, #fff);
}

.list .row {
  background: linear-gradient(180deg, #f6f6f6, #fff);
  color: #2f3e46;
  border-color: currentcolor #e8e8e8 #e8e8e8;
  border-top-color: currentcolor;
  border-top: 1px solid #e8e8e8;
  box-sizing: initial;
  cursor: pointer;
  height: 30px;
  padding: 5px 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row.blue {
  border-top: 1px solid hsl(225, 100%, 92%);
  background-image: linear-gradient(to bottom, hsl(225, 100%, 94%), hsl(225, 100%, 98%));
}
.list a.row.blue:hover {
  background-image: linear-gradient(to bottom, hsl(225, 100%, 90%), hsl(225, 100%, 98%));
}
.row.green {
  border-top: 1px solid hsl(150, 53%, 85%);
  background-image: linear-gradient(to bottom, hsl(150, 53%, 93%), hsl(150, 53%, 97%));
}
.list a.row.green:hover{
  background-image: linear-gradient(to bottom, hsl(150, 53%, 87%), hsl(150, 53%, 97%));
}
.row.yellow {
  border-top: 1px solid hsl(60, 100%, 50%);
  background-image: linear-gradient(to bottom, hsl(60, 100%, 85%), hsl(60, 100%, 98%));
}
.list a.row.yellow:hover {
  background-image: linear-gradient(to bottom, hsl(60, 100%, 75%), hsl(60, 100%, 98%));
}

.list .seatIcon {
  display: inline-block;
  height: 30px;
  left: 0;
  flex-basis: 100px;
  padding-left: 5px;
}

.list .name {
  font-size: 90%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list .startTime {
  height: 30px;
  left: 0;
  padding: 5px 5px 5px 10px;
  font-size: 80%;
  flex-basis: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.list .stakes {
  font-size: 65%;
}

.list .row .registering {
  color: #1c752c;
}

.list .seatCount {
  font-size: 65%;
  margin-top: -1px;
  right: 60px;
  text-align: right;
}

.list .gameDetailsSection {
  flex-grow: 3;
  flex-basis: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list .gameTypeSection {
  flex-grow: 1;
  flex-basis: 100px;
  display: flex;
  flex-direction: column;
}

.list .gameType {
  display: inline;
  font-size: 90%;
  text-align: right;
}

.list .gameType.short {
  display: none;
}

@media (max-width: 600px) {
  .list .gameType.short {
    display: inline;
  }

  .list .gameType {
    display: none;
    font-size: 90%;
    text-align: right;
  }
}

.list .indicators {
  cursor: pointer;
  height: 37px;
  right: 0;
  min-width: 80px;
  display: flex;
  flex-basis: 80px;
  justify-content: flex-end;
}
