.timeBank {
  grid-area: timebank;
  align-self: end;
  justify-self: end;
  margin: 0px 8px 2.4em 0;
  font-size: 1em;
  background-color: #1f2325;
  z-index: 1203;
  padding: .9em;
  border-radius: 1em;
  display: flex;
  color: #999;
  height: 4em;
}

.timeBank span {
  align-self: end;
  height: 100%;
}

.icon {
  height: 100%;
}

.balance {
  font-size: 2em;
  text-shadow: 0 0px 1px #000;
  z-index: 2;
  align-self: center;
  padding-left: .2em;
}

.isEnabled {
  color: #CCC;
}

.isEnabled :global .cross {
  display: none;
}

:global .format-mobile :local .timeBank,
:global .format-tablet :local .timeBank {
  grid-area: table-controls;
  margin: 0 1em 2em 0;
}
