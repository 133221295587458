.indicators {
  padding: 10px;

  border: none;

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  background-color: transparent;
  color: inherit;
}

.indicator + .indicator {
  margin-left: 6px;
}

.participating {
  color: hsl(195, 100%, 50%);
}

.pinned {
  color: hsl(108, 78%, 47%);
}

.priority {
  color: hsl(31, 100%, 50%);
}

.unpinned {
  color: inherit;
  opacity: 0.2;
}
.indicators:hover .pinned {
  opacity: 0.6;
}
.indicators:hover .unpinned {
  opacity: 0.6;
}
